<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane key="1" tab="模板列表">
        </a-tab-pane>
        <a-tab-pane key="2" tab="草稿箱">
        </a-tab-pane>
      </a-tabs>
      <a-table :pagination='false' :loading='listLoading'  rowKey='id' :columns='columns' :data-source='data' bordered >
        <template v-slot:action="{ record }">
          <a-dropdown>
            <a class="ant-dropdown-link"> 操作
              <DownOutlined />
            </a>
            <template v-slot:overlay>
              <a-menu>
                <a-menu-item v-if="active==1">
                  <a v-has:agentCodeTempalteEdit="()=>edit(record)" href="javascript:;">编辑</a>
                </a-menu-item>
                <a-menu-item v-if="active==2">
                  <a v-has:agentDraftToTempalte="()=>agentDraftToTempalte(record)" href="javascript:;">添加至模板库</a>
                </a-menu-item>
                <a-menu-item v-if="active==1">
                  <a v-has:appletCodeTemplateDel="()=>appletCodeTemplateDel(record)" href="javascript:;">删除</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-table>
      <a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange" @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
    </a-card>
  </a-layout>
  <a-modal v-model:visible="visible" title="模板" @ok="handleOk" centered :confirm-loading="confirmLoading" destroyOnClose>
    <a-form ref="ruleForm" :model="form" :label-col="{span:4}" :wrapper-col="{span:20}">
      <a-form-item ref="template_name" label="模板名称" name="template_name" help="最多10个字">
        <a-input v-model:value="form.template_name" />
      </a-form-item>
      <a-form-item ref="type_id" label="模板类型" name="type_id" >
        <a-select v-model:value="form.type_id">
          <a-select-option :value="1">
            默认
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item ref="remarks" label="备注" name="remarks">
        <a-textarea v-model:value="form.remarks" />
      </a-form-item>
    </a-form>
  </a-modal>

</template>
<script>
import { DownOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, inject, reactive, toRefs } from "vue";
import { message } from "ant-design-vue";
import { getRouter } from "@/utils/app";
import {
  getMiniappList,
  getMinidraftList,
  delMiniapp,
  addTemplate,
  editTemplate,
  getMiniappListsync,
  getMiniappDraftsync
} from "@/api/appletCode";
import { $iscode } from "@/utils/app";
// 初始化默认筛选项数值
let defSearch = {
  page: 1,
  limit: 10,
};
export default {
  components: { DownOutlined },
  setup(props, ctx) {
    // // 初始化 分页信息和筛选项信息
    let search = ref({ ...defSearch });
    // 列表数据和列头配置
    let data = ref([]);
    const state = reactive({
      listLoading: false,
      confirmLoading: false,
      total: 1,
      visible: false,
      active: 1,
      templateVisible:false
    });
    let columns = [
         {
        title: "版本号",
        dataIndex: "user_version",
        key: "user_version",
        width:120
      },
      {
        title: "版本描述",
        dataIndex: "user_desc",
        key: "user_desc",
      },
      {
        title: "appID",
        dataIndex: "source_miniprogram_appid",
        key: "source_miniprogram_appid",
      },
      {
        title: "小程序名称",
        key: "source_miniprogram",
        dataIndex: "source_miniprogram",
      },
      {
        title: "发布者",
        dataIndex: "developer",
        key: "developer",
      },
  
      {
        title: "创建时间",
        dataIndex: "create_time",
        key: "create_time",
      },
      {
        title: "操作",
        key: "action",
        slots: { customRender: "action" },
      },
    ];
    const $router = inject("$router");
    const $Modal = inject("$Modal");
    // 页面筛选项搜索
    const pageSearchChange = () => {
      search.value = { ...search.value, page: 1 };
      initData(search.value);
    };
    // 分页当前页切换
    const pageCurrentChange = (page, pageSize) => {
      search.value.page = page;
      initData(search.value);
    };
    // 分页当前页显示多少条切换
    const pageSizeChange = (current, limit) => {
      search.value.page = 1;
      search.value.limit = limit;
      initData(search.value);
    };
    // 小程序模板添加至草稿箱
    const form = ref();
    const agentDraftToTempalte = async (e) => {
      const hide = message.loading("正在加载中...", 0);
      let res = await addTemplate({draft_id:e.draft_id}).then((res) => res.data);
      setTimeout(hide);
      state.visible = false;
      $iscode(res, true);
    };
    const edit=(e)=>{
      state.visible = true;
      form.value = e;
      form.value.type_id=1
    }
    const handleOk = async (e) => {
      state.confirmLoading = true;
      let res = await editTemplate(form.value.id,form.value).then((res) => res.data);
      state.confirmLoading = false;
      state.visible = false;
      $iscode(res, true);
    };
    //小程序列表
    const initData = async (values) => {
     
      state.listLoading = true;
      let res;
      if (state.active == 1) {
         let result=await getMiniappListsync().then(res=>res.data).catch(error=>error)
        res = await getMiniappList(values).then((res) => res.data);
      } else {
         let result=await getMiniappDraftsync().then(res=>res.data).catch(error=>error)
        res = await getMinidraftList(values).then((res) => res.data);
      }
      state.listLoading = false;
      if ($iscode(res)) {
        console.log(res)
        data.value = res.data.data;
        state.total = res.data.total;
      }
    };
    onMounted(() => {
      initData(search.value);
    });
    const callback = (e) => {
      state.active = e;
      search.value.page = 1;
      initData(search.value);
    };
    // 删除
    const appletCodeTemplateDel = (data) => {
      $Modal.confirm({
        content: "确定要删除该模板么",
        centered:true,
        onOk: async () => {
          let res = await delMiniapp({ id: data.id }).then(
            (res) => res.data
          );
          if ($iscode(res, true)) {
            initData(search.value);
          }
        },
      });
    };
    return {
      ...toRefs(state),
      search,
      form,
      data,
      columns,
      pageSearchChange,
      pageCurrentChange,
      pageSizeChange,
      callback,
      agentDraftToTempalte,
      appletCodeTemplateDel,
      handleOk,
      edit
    };
  },
};
</script>